import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'

export default function Navigation() {
    const [openNav, setOpenNav] = useState(false);
    const location = useLocation();
    const [routeName, setRouteName] = useState('utils');

    const manageNavAction = () => {
        setOpenNav(!openNav);
    }

    useEffect(() => {
        setOpenNav(false);
        
        switch(location.pathname) {
            case '/': setRouteName('utils'); break;
            case '/clipboard': setRouteName('Clipboard'); break;
        }
    }, [location]);

    return <div className='bg-black text-white w-full fixed top-0'>
        <button onClick={manageNavAction} className='w-full flex justify-between items-center px-6 h-16'>
            <h1 className='text-3xl font-bold'>{routeName}</h1>
            <FontAwesomeIcon style={{
                transform: openNav ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.4s'
            }} size='xl' icon={faArrowDown} />
        </button>
        <div style={{
            maxHeight: openNav ? '100vh' : 0,
            transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            overflow: 'hidden'
        }}>
            <Link to='/'><p className='w-full px-6 py-2'>Home</p></Link>
            <Link to='/clipboard'><p className='w-full px-6 py-2'>Clipboard</p></Link>
        </div>
    </div>
}