import logo from './logo.svg';
import './App.css';
import Navigation from './fragments/Navigation.tsx';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Index from './pages/Index.tsx'
import Clipboard from './pages/Clipboard.tsx'

function App() {

  return (
    <BrowserRouter className="App">
      <Navigation />
      <div className='pt-20 lg:max-h-screen'>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="clipboard" element={<Clipboard />} />
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
