import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { io } from 'socket.io-client';

export default function Clipboard() {

    const [socket, setSocket] = useState<any>(null);
    const [token, setToken] = useState<string | null>(null);
    const [text, setText] = useState('');
    const [clipboard, setClipboard] = useState([]);
    const [writtenToken, setWrittenToken] = useState('');

    useEffect(() => {
        // setSocket(io('localhost:7000'));
        setSocket(io('https://utils.steelspikes.me:7000'));
    }, []);

    useEffect(() => {
        if(token !== null) {
            socket.emit('setToken', token);
        }
    }, [token]);

    useEffect(() => {
        if (socket !== null) {
            socket.on('tokenGenerated', _token => {
                setToken(_token);
            });

            socket.on('clipboardResponse', _clipboardData => {
                setClipboard(_clipboardData);
            })
        }
    }, [socket]);

    const requestToken = () => {
        socket.emit('requestToken');
    }

    const addTextToClip = e => {
        e.preventDefault();
        socket.emit('addTextToClip', text);
    }

    const importClipboard = () => {
        setToken(writtenToken);
    }

    return <div className="grid grid-cols-1 lg:grid-cols-2 text-lg">
        <div className="col-span-1 px-20 py-5">
            <form onSubmit={addTextToClip} className="grid grid-cols-4">
                <p className="pt-4 col-span-4">Text to add to clipboard:</p>
                <input type="text" placeholder="Paste your text here" value={text} onChange={e => setText(e.target.value)} className="input-style-1 px-4 py-2 col-span-3" />
                <button type="submit" className="bg-white text-black px-4 py-2 rounded-lg col-span-1">Add</button>
            </form>

            <div className="grid grid-cols-3 gap-2 mt-4">
                {
                    clipboard.map((x: string) => <div className="clipboard-item rounded-md col-span-1 px-4 py-1 text-ellipsis overflow-hidden whitespace-nowrap w-full cursor-pointer">
                        {x}
                    </div>)
                }
            </div>
        </div>

        <div className="col-span-1 h-full flex flex-col gap-2 px-20 py-2">
            {
                token !== null ? <>
                    <p className="text-2xl font-bold">Your clipboard token: </p>
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap w-full">
                        <FontAwesomeIcon className="cursor-pointer" onClick={() => navigator.clipboard.writeText(token)} style={{
                            transition: '0.4s'
                        }} size='1x' icon={faCopy} />
                        &#160;{token}</p>
                </> : <p className="text-2xl font-bold">You don't have a token! :/</p>
            }
            <p className="pt-4">I have a token from another clipboard</p>
            <input type="text" value={writtenToken} onChange={e => setWrittenToken(e.target.value)} placeholder="Paste the token here" className="input-style-1 px-4 py-2 col-span-1" />
            <button onClick={importClipboard} className="bg-white text-black px-4 py-2 rounded-lg">Open clipboard</button>
            <p className="pt-4">Help me to create one</p>
            <button onClick={requestToken} className="bg-white text-black px-4 py-2 rounded-lg">Create token</button>
        </div>
    </div>
}